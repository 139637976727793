import React from "react"
import PropTypes from "prop-types"

export default function card_tall(props) {
  const { id, title, subtitle, description, content } = props
  return (
    <div className={`card-tall`}>
      <div className={`card-tall__image card-tall__image${id}`}>
        <h1>{title}</h1>
      </div>
      <div className="card-tall__content">
        <h2>{subtitle}</h2>
        <p>{description}</p>
        {content}
      </div>
    </div>
  )
}

card_tall.defaultProps = {
  id: `1`,
  title: `Card Title`,
  subtitle: `Card Subtitle`,
  description: `Card Description`
}

card_tall.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node,
}
