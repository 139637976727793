import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { FaWindowClose } from "react-icons/fa"
import Img from "gatsby-image/withIEPolyfill"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    backgroundColor: "#000",
    border: "2px solid #fffccc",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "80vh",
    maxWidth: "90vw",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 6,
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('.modal')

class modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  render() {
    const { title, subtitle, description, modalImage } = this.props
    return (
      <div className="textModal">
        <button onClick={this.openModal}>Read More</button>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Text Modal"
        >
          <FaWindowClose
            onClick={this.closeModal}
            className="modal__closeButton"
          />
          <div className="textModal__content">
            <Img
              fixed={modalImage}
              className="modal__image"
              style={{
                margin: "0 auto",
                marginBottom: "2rem",
                border: "2px solid #fffccc",
              }}
            />
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
            <p>{description}</p>
          </div>
        </Modal>
      </div>
    )
  }
}

modal.defaultProps = {
  title: `Card Title`,
  subtitle: `Card Subtitle`,
  description: `Card Description`,
}

modal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  linkUrl: PropTypes.string,
  modalImage: PropTypes.object,
}

export default modal
