import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"
import CardTall from "../elements/card_tall"
import Heading from "../elements/heading"
import Modal from "../elements/text_modal"

const RestaurantPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      modalImage1: file(relativePath: { eq: "modal1.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      modalImage2: file(relativePath: { eq: "modal2.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      modalImage3: file(relativePath: { eq: "modal3.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "restaurant" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description="New Macau Casino features a fully fledged restaurant where guests can enjoy fine Indian, Chinese and Western cuisine."
      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "restaurant_title" })}
        sliderSubtitle={intl.formatMessage({ id: "restaurant_subtitle" })}
        objectPosition="10% 50%"
        button={{ text: "View Menu", link: "https://www.menu.newmacaucasino.com/" }}
      />
      <Subscribe />
      <div className="hospitality__wrapper">
        <Heading
          title={intl.formatMessage({ id: "restaurant_heading" })}
          description={intl.formatMessage({ id: "restaurant_subheading" })}
        />

        <div className="hospitality">
          <CardTall
            id="4"
            title={intl.formatMessage({ id: "restaurant_card1_title" })}
            subtitle={intl.formatMessage({ id: "restaurant_card1_subtitle" })}
            description={intl.formatMessage({
              id: "restaurant_card1_description",
            })}
            content={
              <Modal
                title={intl.formatMessage({ id: "buffet_title" })}
                subtitle={intl.formatMessage({ id: "buffet_subtitle" })}
                description={intl.formatMessage({ id: "buffet_description" })}
                modalImage={data.modalImage1.childImageSharp.fixed}
              />
            }
          />
          <CardTall
            id="5"
            title={intl.formatMessage({ id: "restaurant_card2_title" })}
            subtitle={intl.formatMessage({ id: "restaurant_card2_subtitle" })}
            description={intl.formatMessage({
              id: "restaurant_card2_description",
            })}
            content={
              <Modal
                title={intl.formatMessage({ id: "aLaCarte_title" })}
                subtitle={intl.formatMessage({ id: "aLaCarte_subtitle" })}
                description={intl.formatMessage({ id: "aLaCarte_description" })}
                modalImage={data.modalImage2.childImageSharp.fixed}
              />
            }
          />
          <CardTall
            id="6"
            title={intl.formatMessage({ id: "restaurant_card3_title" })}
            subtitle={intl.formatMessage({ id: "restaurant_card3_title" })}
            description={intl.formatMessage({
              id: "restaurant_card3_description",
            })}
            content={
              <Modal
                title={intl.formatMessage({ id: "beverages_title" })}
                subtitle={intl.formatMessage({ id: "beverages_subtitle" })}
                description={intl.formatMessage({
                  id: "beverages_description",
                })}
                modalImage={data.modalImage3.childImageSharp.fixed}
              />
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(RestaurantPage)
